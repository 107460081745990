import * as React from 'react';
import { Link, graphql } from 'gatsby';

import SideBarDonate from '../components/SideBarDonate/SideBarDonate';
import PageLayout from '../components/PageLayout/PageLayout';
import SEO from '../components/seo';
import BlogContainer from '../containers/BlogContainer/BlogContainer';
import SectionLayout from '../components/SectionLayout/SectionLayout';
import Team from '../components/Team/Team';

const About = ({ data, location }) => {
  const siteTitle = data.site.siteMetadata?.title || `Title`;
  const posts = data.about.nodes;
  const {
    donateSettings: { current, goal },
  } = data;

  return (
    <PageLayout location={location} title={siteTitle}>
      <SEO title="About" />

      <SectionLayout
        title="About"
        sidebar={<SideBarDonate current={current} goal={goal} />}
      >
        {posts.map((post) => (
          <div>
            <h2>{post.frontmatter.title}</h2>
            <section
              dangerouslySetInnerHTML={{ __html: post.html }}
              itemProp="articleBody"
            />

            {post.frontmatter.team ? (
              <Team team={post.frontmatter.team} />
            ) : null}
          </div>
        ))}
      </SectionLayout>
    </PageLayout>
  );
};

export default About;

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    donateSettings: settingsYaml(section: { eq: "donate" }) {
      current
      goal
      reason
    }
    about: allMarkdownRemark(
      sort: { fields: [frontmatter___order], order: ASC }
      filter: { frontmatter: { section: { eq: "about" } } }
    ) {
      nodes {
        html
        frontmatter {
          title
          description
          team {
            name
            position
            description
          }
        }
      }
    }
  }
`;

import * as React from 'react';
import { Link } from 'gatsby';

import TeamMember from '../TeamMember/TeamMember';

import * as styles from './Team.module.scss';

const Team = ({ team }) => {
  return (
    <div className={styles.wrapper}>
      {team.map((member) => (
        <TeamMember {...member} />
      ))}
    </div>
  );
};

export default Team;

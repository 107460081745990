import * as React from 'react';
import { Link } from 'gatsby';
import { getImage, GatsbyImage, StaticImage } from 'gatsby-plugin-image';

import * as styles from './TeamMember.module.scss';

const TeamMember = ({ description, name, photo, position }) => {
  return (
    <div className={styles.member}>
      <h3 className={styles.name}>{name}</h3>
      <div className={styles.imageContainer}>
        {photo ? (
          <GatsbyImage
            alt={name}
            className={styles.image}
            image={getImage(photo)}
          />
        ) : (
          <StaticImage
            className={styles.image}
            layout="constrained"
            formats={['AUTO', 'WEBP', 'AVIF']}
            src="./../../images/avatar.png"
            width={500}
            quality={95}
            alt={name}
          />
        )}
      </div>
      <p className={styles.position}>Position: {position}</p>
      <p className={styles.description}>{description}</p>
    </div>
  );
};

export default TeamMember;
